















































import Vue from 'vue';
// import BreadCrumb from '@/components/BreadCrumb.vue';

export default Vue.extend({
  name: 'Support',
  // components: {
  //   BreadCrumb,
  // },
});
